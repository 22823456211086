import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import G from './G';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);



  const imageUrls = [
    'https://i.ibb.co/TPCgQqS/cropped-IMG-9431.jpg',
    'https://i.ibb.co/5F6hhxj/cropped-SNOW-20240425-105236-940.jpg',
    'https://i.ibb.co/z81Nppc/cropped-IMG-9471.jpg',
    'https://i.ibb.co/Msn38Nj/cropped-IMG-5281.jpg',
    'https://i.ibb.co/rMHqz5m/cropped-SNOW-20240425-103425-302.jpg',
    'https://i.ibb.co/kcp65Zf/cropped-SNOW-20240425-103201-491.jpg',
    'https://i.ibb.co/WkFTRjb/cropped-SNOW-20240425-102657-040.jpg',
    'https://i.ibb.co/tPYsNd8/cropped-SNOW-20240425-105326-511.jpg'
  ];

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container" >

            <video
                autoPlay
                playsInline
                preload="auto"
                loop
                muted
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '115%',
                  top: 0,
                  left: 0,
                  objectFit: 'cover',
                  zIndex: -1,
                }}
              >
                <source src="./images/background3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ 
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '31px', margin: '0 0 10px 0' }}> 
            <strong>🎁FREE NOW🎁 Kaya Yume 💕</strong>
        </p>
        <div className="detailsText">
            <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️28 years ♉ Taurus<br/></strong>
            🟢 Online Now<br/><br/>

            🍑 💦 Do you live near<strong> {city}</strong>?📍 DM me about making content 😉 🍑 🔥
        </div>
    </div>



      <a href="https://onlyfans.com/action/trial/gpwggvje0ibnsqduf2ifulzg24vmfknl" id="customButton" >
      VIP exclusive page
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <a href="https://onlyfans.com/action/trial/po7wvuis94gdzpkurrlu2b1qpozl8l54" id="customButton" >
      Teaser page
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
       <strong>Get access to my private account now!<br/>
       Get 1 month free access upon subscription.</strong>
      </p>
      <Analytics/>
    </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/kayayumequeen" element={<G />} />
</Routes>
</Router>

  );
}

export default App;
